import React from 'react';
import { Fab } from '@mui/material';
import { styled } from '@mui/material/styles';

import Tryomni from '../assets/img/onboard/extra/tryomni.jsx';
import ChatIcon from '../assets/img/onboard/chatIcon.jsx';

const Heroimg = styled('div')({
  width: '100%',
  marginRight: 'calc(-8.2rem)',
  position: 'relative'
});

const ImgContent = styled('div')({
  cursor: 'pointer',
  position: 'relative'
});
const IMGicon1 = styled('img')({
  position: 'absolute'
});

const Theme1 = ({
  extra,
  position,
  SocialAdded,
  ShowButton,
  ShowSub,
  getImg,
  url,
  prem,
  try_color,
  faq,
  faqPopupOpenEvent
}) => {
  const faqOpenEvent = () => {
    faqPopupOpenEvent();
  };
  // const faq_page = open ? 'simple-popover' : undefined;
  const topLeft = {
    left: '20px',
    top: '20px'
  };
  const bottomLeft = {
    left: '20px',
    bottom: '20px'
  };
  const topRight = {
    right: '20px',
    top: '20px'
  };
  const bottomRight = {
    right: '20px',
    bottom: '20px'
  };

  const sub_topLeft = {
    top: '122px',
    left: '40px',
    '@media (max-width: 34em)': {
      top: '85px',
      left: '21px'
    }
  };
  const sub_topRight = {
    right: '41px',
    top: '122px',
    '@media (max-width: 34em)': {
      right: '21px',
      top: '82px'
    }
  };
  const sub_bottomLeft = {
    bottom: '122px',
    left: '32px',
    '@media (max-width: 34em)': {
      bottom: '85px',
      left: '21px'
    }
  };
  const sub_bottomRight = {
    right: '36px',
    bottom: '122px',
    '@media (max-width: 34em)': {
      bottom: '85px',
      right: '21px'
    }
  };

  const all_postions = ['', topLeft, topRight, bottomLeft, bottomRight];
  const subIcon_position = ['', sub_topLeft, sub_topRight, sub_bottomLeft, sub_bottomRight];

  const FabStyle = styled(Fab)({
    margin: 0,
    position: 'fixed',
    zIndex: '1000000',
    boxShadow:
      '0px 3px 5px -1px rgb(130 143 162 / 20%), 0px 6px 10px 0px rgb(130 143 162 / 14%), 0px 1px 18px 0px rgb(130 143 162 / 12%)',
    background: '#00B1FF',
    '&:hover': {
      background: extra.selected,
      boxShadow:
        '2px 0px 20px 13px rgb(142 143 162 / 39%), -1px 2px 19px 1px rgb(130 143 162 / 14%), 0px 1px 18px 9px rgb(130 143 162 / 12%)'
    },
    height: '80px',
    width: '80px',
    '@media (max-width: 34em)': {
      width: '50px',
      height: '50px'
    },
    img: {
      height: '30px',
      width: '30px'
    },
    ...all_postions[position]
  });

  const Otherbutton = styled('ul')({
    opacity: ShowButton,
    transition: 'all 0.3s ease',
    transform: `scale(${ShowButton})`,
    transformOrigin: '85% bottom',
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    margin: '0',
    position: 'fixed',
    zIndex: 100000,
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: '80vh',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    img: {
      width: '16px',
      height: '18px'
    },
    ...subIcon_position[position]
  });

  return (
    <Heroimg>
      <FabStyle size="large" sx={{ background: extra.selected }} onClick={() => ShowSub(false)}>
        <IMGicon1
          src={extra.type === 'img' ? extra.value : getImg(extra.type)}
          alt="chat"
          sx={{ filter: extra.type !== 'img' && 'brightness(0) invert(1)' }}
        />
      </FabStyle>
      <Otherbutton>
        {/* {SocialAdded.sort((a, b) => (a.position > b.position ? 1 : -1)).map((data, ind) => (
          <ImgContent key={ind} onClick={() => ShowSub(data)}>
            <IMGRound1 sx={{ background: data.selected }} />
            <IMGiColor>
              <Socialimages color={data.icolor} type={data.type} />
            </IMGiColor>
          </ImgContent>
        ))} */}
        {SocialAdded.sort((a, b) => (a.position > b.position ? 1 : -1)).map((data, ind) => (
          <ImgContent key={ind} onClick={() => ShowSub(data)}>
            <ChatIcon color={data.icolor} type={data.type} background={data.selected} />
          </ImgContent>
        ))}
        {faq ? (
          <ImgContent>
            <a
              style={{ textDecoration: 'none' }}
              onClick={() => faqOpenEvent()}
              rel="noreferrer"
              sx={{ position: 'relative' }}
            >
              <Tryomni
                height="49"
                width="49"
                c={try_color?.color || '#778295'}
                f={try_color?.icolor || '#FFFFFF'}
                type="faq"
              />
            </a>
          </ImgContent>
        ) : (
          ''
        )}
        {prem ? (
          ''
        ) : (
          <ImgContent>
            <a style={{ textDecoration: 'none' }} href={url} target="_blank" rel="noreferrer">
              {/* <img
                style={{ width: '49px', height: '49px', borderRadius: '50% !important' }}
                src={tryomni}
                alt="Tryomni"
              /> */}
              <Tryomni c={try_color?.color || '#778295'} f={try_color?.icolor || '#FFFFFF'} type="tryomni" />
            </a>
          </ImgContent>
        )}
      </Otherbutton>
    </Heroimg>
  );
};
export default Theme1;
