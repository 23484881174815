import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

const omniDiv = document.createElement('div');
omniDiv.className = 'omni--chat';
document.body.appendChild(omniDiv);
const url = document.currentScript.src;
// const api_url = 'http://localhost:3000';
const api_url = 'https://www.chatomni.app';
// const api_url = 'https://omnichat-dev.herokuapp.com';

const token = omnigeValue('token', url);
// const token = 'a9e607b1856452a95138abb085ffa1b3.f5f8d4e9625e4b500aa57132888eafea';
let shop = window?.Shopify?.shop;
shop = shop ? shop : '';
addScript();
load_CSS();
ReactDOM.render(
  <React.StrictMode>
    <App token={token || shop} />
  </React.StrictMode>,
  omniDiv
);

function addScript() {
  let scriptEle = document.createElement('script');
  scriptEle.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=UA-234102138-1');
  scriptEle.setAttribute('async', '');
  document.head.appendChild(scriptEle);

  let scriptEledata = document.createElement('script');
  scriptEledata.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'UA-234102138-1');
    `;
  document.head.appendChild(scriptEledata);
}

function load_CSS() {
  var head = document.getElementsByTagName('head')[0];
  var link = document.createElement('link');
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = `${api_url}/chat/static/css/main.css`;
  link.media = 'all';
  head.appendChild(link);
}
function omnigeValue(e, t) {
  t = '' === t || null === t ? window.location.search : t;
  t = RegExp('[?&]' + e + '=([^&]*)').exec(t);
  return t && decodeURIComponent(t[1].replace(/\+/g, ' '));
}
