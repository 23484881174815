import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tryomni from '../assets/img/onboard/extra/tryomni.jsx';
import Socialimages from '../assets/img/onboard/socialimages.jsx';

const Heroimg = styled('div')({
  width: '100%',
  marginRight: 'calc(-8.2rem)',
  position: 'relative'
});

const ImgContent = styled('div')({
  cursor: 'pointer',
  position: 'relative'
});
const IMGRound1 = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '134px',
  height: '60px',
  '@media (max-width: 34em)': {
    width: '50px',
    height: '50px'
  }
});
const Tryatag = styled('a')({
  textDecoration: 'none',
  width: '134px',
  height: '60px',
  background: '#778295',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '@media (max-width: 34em)': {
    width: '50px',
    height: '50px'
  }
});

const IMGicon1 = styled('img')({
  filter: 'brightness(0) invert(1)',
  position: 'absolute'
});

const Theme2 = ({
  extra,
  position,
  SocialAdded,
  ShowButton,
  ShowSub,
  getImg,
  url,
  prem,
  try_color,
  faq,
  faqPopupOpenEvent
}) => {
  const faqOpenEvent = () => {
    faqPopupOpenEvent();
  };
  const topLeft = {
    left: '40px',
    top: '20px'
  };
  const bottomLeft = {
    left: '40px',
    bottom: '20px'
  };
  const topRight = {
    right: '20px',
    top: '20px'
  };
  const bottomRight = {
    right: '20px',
    bottom: '20px'
  };

  const sub_topLeft = {
    top: '80px',
    left: '40px',
    '@media (max-width: 34em)': {
      top: '80px',
      left: '48px'
    }
  };
  const sub_topRight = {
    right: '20px',
    top: '80px',
    '@media (max-width: 34em)': {
      right: '26px',
      top: '80px'
    }
  };
  const sub_bottomLeft = {
    bottom: '80px',
    left: '40px',
    '@media (max-width: 34em)': {
      bottom: '80px',
      left: '48px'
    }
  };
  const sub_bottomRight = {
    right: '20px',
    bottom: '80px',
    '@media (max-width: 34em)': {
      right: '26px',
      bottom: '78px'
    }
  };

  const all_postions = ['', topLeft, topRight, bottomLeft, bottomRight];
  const subIcon_position = ['', sub_topLeft, sub_topRight, sub_bottomLeft, sub_bottomRight];
  const FabStyle = styled(Button)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 0,
    margin: 0,
    position: 'fixed',
    zIndex: '1000000',
    width: '134px',
    height: '60px',
    boxShadow:
      '0px 3px 5px -1px rgb(130 143 162 / 20%), 0px 6px 10px 0px rgb(130 143 162 / 14%), 0px 1px 18px 0px rgb(130 143 162 / 12%)',
    background: '#00B1FF',
    '&:hover': {
      background: extra.selected,
      boxShadow:
        '2px 0px 20px 13px rgb(142 143 162 / 39%), -1px 2px 19px 1px rgb(130 143 162 / 14%), 0px 1px 18px 9px rgb(130 143 162 / 12%)'
    },
    img: {
      height: '30px',
      width: '30px'
    },
    '@media (max-width: 34em)': {
      width: '50px',
      height: '50px'
    },
    ...all_postions[position]
  });

  const Otherbutton = styled('ul')({
    opacity: ShowButton,
    transition: 'all 0.3s ease',
    transform: `scale(${ShowButton})`,
    transformOrigin: '85% bottom',
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: '1px',
    margin: '0',
    position: 'fixed',
    zIndex: 100000,
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: '90vh',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    ...subIcon_position[position]
  });

  return (
    <>
      <Heroimg>
        <FabStyle sx={{ background: extra.selected }} onClick={() => ShowSub(false)}>
          <IMGicon1 src={extra.type === 'img' ? extra.value : getImg(extra.type)} alt="chat" />
        </FabStyle>
        <Otherbutton>
          {SocialAdded.sort((a, b) => (a.position > b.position ? 1 : -1)).map((data, ind) => (
            <ImgContent key={ind} onClick={() => ShowSub(data)}>
              <IMGRound1 sx={{ background: data.selected }}>
                <Socialimages color={data.icolor} type={data.type} />
              </IMGRound1>
            </ImgContent>
          ))}
          {faq ? (
            <ImgContent>
              <a
                style={{ textDecoration: 'none' }}
                onClick={() => faqOpenEvent()}
                rel="noreferrer"
                sx={{ position: 'relative' }}
              >
                <Tryomni
                  height="49"
                  width="49"
                  c={try_color?.color || '#778295'}
                  f={try_color?.icolor || '#FFFFFF'}
                  type="faq"
                />
              </a>
            </ImgContent>
          ) : (
            ''
          )}
          {prem ? (
            ''
          ) : (
            <ImgContent>
              <Tryatag href={url} target="_blank" rel="noreferrer">
                {/* <img
                  style={{ width: '49px', height: '49px', borderRadius: '50% !important' }}
                  src={tryomni}
                  alt="Tryomni"
                /> */}
                <Tryomni
                  height="30"
                  width="30"
                  c={try_color?.color || '#778295'}
                  f={try_color?.icolor || '#FFFFFF'}
                  type="tryomni"
                />
              </Tryatag>
            </ImgContent>
          )}
        </Otherbutton>
      </Heroimg>
    </>
  );
};
export default Theme2;
