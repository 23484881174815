import React, { useEffect, useState } from 'react';

import wp from './assets/img/onboard/wp.svg';
import fb from './assets/img/onboard/fb.svg';
import ms from './assets/img/onboard/ms.svg';
import tl from './assets/img/onboard/tl.svg';
import sm from './assets/img/onboard/sm.svg';
import em from './assets/img/onboard/em.svg';
import sl from './assets/img/onboard/sl.svg';
import tw from './assets/img/onboard/tw.svg';
import yt from './assets/img/onboard/youtube.svg';
import li from './assets/img/onboard/li.svg';
import ins from './assets/img/onboard/ins.svg';
import yelp from './assets/img/onboard/yelp.svg';
import tiktok from './assets/img/onboard/tiktok.svg';
import sn from './assets/img/onboard/sn.svg';
import lo from './assets/img/onboard/lo.svg';
import ur from './assets/img/onboard/ur.svg';
import discord from './assets/img/onboard/discord.svg';
import twi from './assets/img/onboard/twitch.svg';
import omni from './assets/img/onboard/extra/omni.svg';
import first from './assets/img/onboard/extra/first.svg';
import second from './assets/img/onboard/extra/second.svg';
import three from './assets/img/onboard/extra/three.svg';
import four from './assets/img/onboard/extra/four.svg';
import five from './assets/img/onboard/extra/five.svg';
import six from './assets/img/onboard/extra/six.svg';

import Theme1 from './themes/theme1';
import Theme2 from './themes/theme2';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import './App.css';
import 'react-quill/dist/quill.snow.css';

const url = 'https://www.chatomni.app';
// const url = 'https://omnichat-dev.herokuapp.com';
// const url = 'http://localhost:3000';

function App({ token }) {
  const renderHTML = (rawHTML) => React.createElement('div', { dangerouslySetInnerHTML: { __html: rawHTML } });
  const [faqopen, setFaqopen] = useState(false);
  const [ShowButton, setShowbutton] = useState(0);
  const [SocialAdded, SetSocialAdded] = useState([]);
  const [ip, setIP] = useState('');
  const [position, setPosition] = useState(4);
  const [theme, setTheme] = useState(1);
  const [status, setStatus] = useState(false);
  const [showatFooter, setShowatFooter] = useState(true);
  const [prem, setprem] = useState(false);
  const [try_color, setTrycolor] = useState({});
  const [faq, setFaq] = useState(false);

  const [extra, SetExtra] = useState({
    selected: '#00B1FF',
    type: 'first',
    value: url
  });
  const faq_sub_topLeft = {
    top: '122px',
    left: '40px',
    '@media (max-width: 34em)': {
      top: '85px',
      left: '21px'
    }
  };
  const faq_sub_topRight = {
    right: '41px',
    top: '122px',
    '@media (max-width: 34em)': {
      right: '21px',
      top: '82px'
    }
  };
  const faq_sub_bottomLeft = {
    bottom: '122px',
    left: '32px',

    '@media (max-width: 34em)': {
      bottom: '85px',
      left: '21px'
    }
  };
  const faq_sub_bottomRight = {
    right: '32px',
    bottom: '122px',

    // position: 'relative',
    // flex: '1 1 0%',
    // width: 100%;
    // overflow-y: auto;

    '@media (max-width: 34em)': {
      bottom: '85px',
      right: '21px'
    }
  };
  const popup_position = ['', faq_sub_topLeft, faq_sub_topRight, faq_sub_bottomLeft, faq_sub_bottomRight];
  const FaqPopup = styled('div')({
    transition: 'all 0.3s ease',
    transformOrigin: '75% bottom',
    width: '400px',
    position: 'fixed',
    zIndex: 100000,
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: '0',
    maxHeight: '80vh',
    borderRadius: '10px',
    boxShadow: 'rgb(0 0 0 / 15%) 0px 3px 15px',
    backgroundColor: '#fff',

    '&::-webkit-scrollbar': {
      display: 'none'
    },
    ...popup_position[position]
  });

  const faqPopupOpenEvent = () => {
    setFaqopen(true);
    setShowbutton(0);
  };
  useEffect(() => {
    fetch(`${url}/checkstatus/${token}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          if (data.charge_status) {
            get_data();
          } else if (!data.trial_end) {
            get_data();
          }
        }
        setprem(data.prem);
      })
      .catch((err) => {});
    window.addEventListener('scroll', handleScroll);
  }, []);
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const body = document.body,
      html = document.documentElement;

    let height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    let tenPercent = (height - window.innerHeight) * 0.9;
    if (scrollTop > tenPercent) {
      setShowatFooter(false);
    } else {
      setShowatFooter(true);
    }
  };
  const get_data = () => {
    fetch('https://geolocation-db.com/json/')
      .then((response) => response.json())
      .then((res) => {
        setIP(res.IPv4);
      })
      .catch((err) => console.error('Problem fetching my IP', err));

    fetch(`${url}/chat/social/get_connect/${token}`)
      .then((response) => response.json())
      .then((res) => {
        setStatus(true);
        if (res) {
          SetSocialAdded(res.channels);
          const tryomni_data = res.tryomni;
          setTrycolor(tryomni_data);
          if (res?.extra) {
            SetExtra(res.extra);
            setPosition(res.extra.channelPosition);
            setTheme(res?.extra?.theme || 1);
          }
          if (res.faq) {
            setFaq(JSON.parse(res.faq));
          }
        }
      })
      .catch((err) => console.error('Problem fetching get connect', err));
  };
  const ShowSub = (data) => {
    let send_data = false;
    var date = new Date();
    date.setMinutes(0, 0, 0);
    // date = date_format(date);

    if (data) {
      switch (data.type) {
        case 'SMS':
          window.open('sms:' + data.value, '_blank');
          break;
        case 'Email':
          window.open('mailto:' + data.value, '_blank');
          break;
        default:
          window.open(data?.value.includes('http') ? data.value : `https://${data.value}`, '_blank');
          break;
      }

      send_data = {
        icon_click: 0,
        url: window.location.href,
        channels_id: data.id,
        ip: ip,
        date: date
      };
    } else {
      if (ShowButton) {
        setShowbutton(0);
      } else {
        setShowbutton(1);
        send_data = {
          icon_click: 1,
          url: window.location.href,
          ip: ip,
          date: date
        };
      }
    }
    // call /dashboard/info/update post method
    if (send_data) {
      fetch(`${url}/dashboard/info/update/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: send_data
        })
      });
    }
  };
  const getImg = (d) => {
    let img = '';
    switch (d) {
      case 'WhatsApp':
        img = wp;
        break;
      case 'Facebook':
        img = fb;
        break;
      case 'Messenger':
        img = ms;
        break;
      case 'Telegram':
        img = tl;
        break;
      case 'SMS':
        img = sm;
        break;
      case 'Email':
        img = em;
        break;
      case 'Slack':
        img = sl;
        break;
      case 'Twitter':
        img = tw;
        break;
      case 'YouTube':
        img = yt;
        break;
      case 'LinkedIn':
        img = li;
        break;
      case 'Instagram':
        img = ins;
        break;
      case 'Snapchat':
        img = sn;
        break;
      case 'Discord':
        img = discord;
        break;
      case 'Twitch':
        img = twi;
        break;
      case 'Yelp':
        img = yelp;
        break;
      case 'TikTok':
        img = tiktok;
        break;
      case 'Location':
        img = lo;
        break;
      case 'URL':
        img = ur;
        break;
      case 'omni':
        img = omni;
        break;
      case 'first':
        img = first;
        break;
      case 'second':
        img = second;
        break;
      case 'three':
        img = three;
        break;
      case 'four':
        img = four;
        break;
      case 'five':
        img = five;
        break;
      case 'six':
        img = six;
        break;
      default:
        img = omni;
        break;
    }
    return img;
  };

  const closePopups = () => {
    ShowSub(false);
    setFaqopen(false);
  };

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  }));
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
  ))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1)
    }
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)'
  }));
  return (
    <>
      {showatFooter ? (
        status ? (
          theme === 2 ? (
            <Theme2
              extra={extra}
              position={position}
              SocialAdded={SocialAdded}
              ShowButton={ShowButton}
              ShowSub={ShowSub}
              getImg={getImg}
              url={url}
              prem={prem}
              try_color={try_color}
              faq={faq}
              faqPopupOpenEvent={faqPopupOpenEvent}
            />
          ) : (
            <Theme1
              extra={extra}
              position={position}
              SocialAdded={SocialAdded}
              ShowButton={ShowButton}
              ShowSub={ShowSub}
              getImg={getImg}
              url={url}
              prem={prem}
              try_color={try_color}
              faq={faq}
              faqPopupOpenEvent={faqPopupOpenEvent}
            />
          )
        ) : (
          <></>
        )
      ) : (
        ''
      )}

      {faqopen ? (
        <div>
          <Card sx={{ minWidth: 275, zIndex: 100000 }}>
            <FaqPopup>
              <div style={{ textAlign: 'right', padding: '1rem 1rem 0 1rem ' }}>
                <IconButton onClick={() => closePopups()}>
                  <CloseIcon />
                </IconButton>
              </div>

              <CardContent sx={{ padding: '0' }}>
                <div>
                  {faq
                    ? faq
                        .sort((a, b) => Number(a.position) - Number(b.position))
                        .map((fq, i) => (
                          <Accordion expanded={expanded === i} onChange={handleChange(i)} sx={{ margin: '0' }}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>{fq.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="ql-snow">
                                <div className="ql-editor">{renderHTML(fq.answer)}</div>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        ))
                    : null}
                </div>
              </CardContent>
            </FaqPopup>
          </Card>
        </div>
      ) : null}
    </>
  );
}

export default App;
